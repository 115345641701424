import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavHeaderBarComponent } from './nav-header-bar/nav-header-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavSideBarComponent } from './nav-side-bar/nav-side-bar.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { LoaderCommonComponent } from '../shared/loader/loader-common.component';
import { CohertComponent } from './cohert/cohert.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EmptyStateModule } from './empty-state/empty-state.module';
import { MultiCohertComponent } from '../shared/multi-cohert/multi-cohert.component';
// import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
// import { BrowserModule } from '@angular/platform-browser';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';


@NgModule({
  imports: [
    CommonModule,
    // BrowserModule,
    // AngularMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    EmptyStateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    // AngularDateTimePickerModule,
    NgSelectModule
  ],
  declarations: [
    NavHeaderBarComponent,
    NavSideBarComponent,
    MultiSelectComponent,
    LoaderCommonComponent,
    CohertComponent,
    MultiCohertComponent
  ],
  exports: [
    NavHeaderBarComponent,
    NavSideBarComponent,
    MultiSelectComponent,
    LoaderCommonComponent,
    CohertComponent,
    MultiCohertComponent
  ]
})
export class SharedModule { }
// platformBrowserDynamic().bootstrapModule(SharedModule);
