import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { StorageService } from '../../../services/storage.service';
import { AllsetupService } from '../../../services/allsetup.service';
import * as toastr from 'toastr';

@Component({
  selector: 'app-nav-header-bar',
  templateUrl: './nav-header-bar.component.html',
  styleUrls: ['./nav-header-bar.component.css'],
  providers: [StorageService, AllsetupService]
})
export class NavHeaderBarComponent implements OnInit {
  userName: String = 'Admin';
  userType: String;
  prevTarget: any;
  constructor(public router: Router,
    public _storage: StorageService,
    private route: ActivatedRoute,
    public _service: AllsetupService) {
  }
  ngOnInit() {
    this.findActiveClass();
  }
  onLoggedout() {
    const json = {};
    //location.replace('https://idm.zsservices.com/MomentZS/IdentityManager/app/web/logout.aspx?ReturnUrl=https://mzs-ci.zsservices.com');
    //  location.replace('https://idm.dev.zsservices.com/MomentZS/IdentityManager/app/Web/logout.aspx');

    this._service.logout_user(json).subscribe(data => {
      // if (data['status'] === 1) {
      this._storage.api.local.clear();
      this._storage.api.session.clear();
      // this.router.navigate(['login']);
      location.replace('https://idm.dev.zsservices.com/MomentZS/IdentityManager/app/Web/logout.aspx');

      // }
      // }, err => {
      //   console.log(err);
      //   toastr.error('Failed to Logout. Please try again');
    });
  }
  findActiveClass() {
    const splittedUrl = location.href.split('home/')[1].split('/');
    const Audio_Details = location.href.indexOf('Audio_Details');
    const activeCls = Audio_Details === -1 ? 'tap-stream' : 'audio-data';
    if (splittedUrl.length === 2) {
      $('.' + activeCls).addClass('active');
    } else {
      $('.' + splittedUrl).addClass('active');
    }
    $('.main_menu').click(function (e) {
      $('.level1').removeClass('active');
      $('.main_menu').removeClass('active');
      $(e.currentTarget).addClass('active');
    });
    $('.sub_menuLink').click(function (e) {
      $('.main_menu').removeClass('active');
      $('.level1').removeClass('active');
      $(e.currentTarget).parents('div.dropdown-menu').siblings('a.level1').addClass('active');
    });
  }
  navigateTo(url) {
    this.router.navigate(['/landing/home/' + url]);
  }
}
