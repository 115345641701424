<div class="row form-group zs_field">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-5 row">
        <label class="container_checkbox newContainerClass">
          <span>{{ typeLabel }}</span>
          <input *ngIf="action == 'inLevel'" type="checkbox" [disabled]="index == 0"
            [checked]="postObject['inLevelCohortSelected']" (click)="switchPrimary()">
          <input *ngIf="action == 'outLevel'" type="checkbox" [checked]="postObject['outLevelCohortSelected']"
            (click)="switchPrimary()">
          <span class="checkmark_checkbox"></span>
        </label><br>
        <span *ngIf="index == 0 && action == 'inLevel'" style="padding-top: 6px;
        padding-left: 10px;">
          <h6><b>Note</b> : There is no previous task</h6>
        </span>
      </div>
      <div class="col-md-1">
        &nbsp; <br />
        <button class="btn btn-primary" (click)="addcohortList(single, index)">
          <b><i class="fa fa-plus"></i></b>
        </button>
      </div>
      <div class="col-md-6">
        <div class="row">
        <div class="col-md-6 my-padding ">
          <label>Parent Condition Logic:</label>
        </div>
        <div class="col-md-6">

            <input type="text" [(ngModel)]="single" class="form-control" (keydown)="onKeydown($event, item)">
        </div>
      </div>
      </div>
      <div *ngIf="(postObject['inLevelCohortSelected']) || postObject['outLevelCohortSelected']" class="col-md-12 card">
        <div class="multi-cohort-list" *ngFor="let single of testObject; let i=index" class="col-md-12 card">

        <div class="">
          <div class="col-md-12 adjustpadding">
            <div class="col-md-12 row">
              <div class="col-md-4 my-padding ">
                <div class="row">
                <div class="col-md-4"><label>Template Id:</label></div>
                <div class="col-md-8"><input type="text" class="form-control" [(ngModel)]="single.tableId"></div>
              </div>
            </div>
              <div class="col-md-4 my-padding ">
                <div class="row">
                <div class="col-md-5"><label>Multiple Condition Logic:</label></div>
                <div class="col-md-7">
                  <ng-select [items]="multiplelogic" [(ngModel)]="single.logic" [allowClear]="false"
                  [multiple]="false">
                </ng-select>
              </div>
            </div>
              </div>
              <div class="col-md-2">
                <div class="row">
                <button class="btn btn-danger" (click)="deletecohortList(index)">
                  <b><i class="fa fa-trash"></i></b>
                </button>
              </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <h6>Condition Name:</h6>
              <input type="text" class="condition" *ngIf="show" [(ngModel)]="single['conditionName']"
                class="form-control" />
            </div>
            <div class="col-md-3">
              <h6>Cohorting Type:</h6>
              <ng-select [items]="cohortingTypes" placeholder="Select Cohort Type"
                [(ngModel)]="single['cohortType']" (ngModelChange)="cohortingTypeChange($event)"
                [allowClear]="false" [multiple]="false">
              </ng-select>
            </div>
            <div class="col-md-3">
              <h6>Select Task:</h6>
              <ng-select [items]="selectTaskTypes" (ngModelChange)="inputChange($event, selectTaskTypes)"
                [(ngModel)]="single['task']" [allowClear]="false" [multiple]="false">
              </ng-select>
            </div>
            <div *ngIf="isEdit" class="col-md-1" style="margin-top: 26px; padding-bottom: 0px">
              <button class="btn btn-info btn-sm" (click)="updateOption(single)">
                <i class="fa fa-edit"></i></button>
            </div>
            <div class="col-md-1" style="margin-top: 26px; padding-bottom: 0px">
              <button class="btn btn-sm btn-info" (click)="addObjectTreatment(single)" (ngModelChange)="editList()"
                [disabled]="!isValid">
                <i class="fa fa-plus"></i></button>
            </div>
          </div>
          <div class="row"
            *ngIf="showConditionBlock && (single['cohortType'] == 'Specific Input' && single['task'])">
            <div class="col-md-3">
              <h6>Condition in task</h6>
              <ng-select [items]="conditionInTaskTypes" (ngModelChange)="handleSingleInputDropdown($event)"
                placeholder="Select Cohort Type" [(ngModel)]="single['conditionInTask']" [allowClear]="false"
                [multiple]="false">
              </ng-select>
            </div>
            <div class="col-md-3" *ngIf="!isMR">
              <h6>Select Input</h6>
              <ng-select [items]="SELECT_INPUT" [disabled]="!single['task'] || !single['conditionInTask']"
                [(ngModel)]="single['selectInput']" [multiple]="true">
              </ng-select>
            </div>
            <ng-container *ngIf="isMR && questionIDs">
              <div class="col-md-3">
                <h6>Select Question</h6>
                <ng-select [items]="questionIDs" [(ngModel)]="cohortData.questionID"
                  (ngModelChange)="changeQuestion($event)">
                </ng-select>
              </div>
              <ng-container *ngIf="questionDetails">
                <ng-container *ngIf="operatorBasedQuestions.indexOf(questionDetails.type) > -1">
                  <div class="col-md-2">
                    <h6>Operator</h6>
                    <ng-select [items]="cohortData.operatorsList" [(ngModel)]="cohortData.operator"
                      [multiple]="false">
                    </ng-select>
                  </div>
                  <div class="col-md-3">
                    <h6>Value</h6>
                    <input type="number" oninput="validity.valid||(value='');" class="form-control"
                      [(ngModel)]="cohortData.value" [attr.max]="questionDetails.maxValue">
                  </div>
                </ng-container>
                <ng-container *ngIf="questionDetails.type !== 'openEndedNumeric'">
                  <div class="col-md-3">
                    <h6>Select Option ID</h6>
                    <ng-select [items]="cohortOptions" [(ngModel)]="cohortData.options" [multiple]="true"
                      (ngModelChange)="changeQuestion($event)">
                    </ng-select>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <div class="row">
            <div class="col-md-12" *ngIf="single['cohortType'] == 'Response Based' && single['task']">
              <div class="pull-right">
                <label class="container_checkbox">
                  <p>Has Response</p>
                  <input type="checkbox" float="right" checked="single['hasResponse']"
                    (click)="changeHasResponse()" [(ngModel)]="single['hasResponse']">
                  <span class="checkmark_checkbox"></span>
                </label>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <table class="tableStyle table table-bordered">
                <thead>
                  <tr>
                    <th style="width:118px">Condition No</th>
                    <th>Condition Name</th>
                    <th>Condition Type</th>
                    <th>Selected Cohort Values</th>
                    <th>Question Id</th>
                    <th>Has Reponse</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let condition of single.conditionsList;let i=index;">
                    <td>{{i+1}}</td>
                    <td>
                      <span *ngIf="!condition['edit']">{{condition.conditionName}}</span>
                      <input *ngIf="condition['edit']" [(ngModel)]="condition.conditionName" />
                    </td>
                    <td>
                      <span *ngIf="!condition['edit']">{{condition.task}}</span>
                      <input *ngIf="condition['edit']" [(ngModel)]="condition.task" />
                    </td>
                    <td>
                      <ng-container *ngIf="!condition.cohortValue || !condition.cohortValue.operator">
                        {{condition.cohortLabels}}
                      </ng-container>
                      <ng-container *ngIf="condition.cohortValue && condition.cohortValue.operator">
                        {{condition.cohortLabels}} {{condition.cohortValue.operator}} {{condition.cohortValue.value}}
                      </ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="condition.cohortValue else naCell">
                        {{condition.cohortValue.questionID}}
                      </ng-container>
                      <ng-template #naCell>
                        NA
                      </ng-template>
                    </td>
                    <td>{{condition.hasResponse? 'Yes': 'No'}}</td>
                    <td>
                      <button [disabled]="hideIcon(condition)">
                        <i class="fa fa-eye" (click)="editList(condition,i)"></i>
                      </button>
                      <i class="fa fa-trash" (click)="remove(condition,i)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  </div>
</div>
